import * as React from "react"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import Button from "../components/button"

const mainContainer = {
  minHeight: "calc(100vh - 504px)"
}

const headingStyles = {
  paddingTop: 50,
  paddingBottom: 50,
}

const buttonContainer = {
  paddingBottom: 120,
  textAlign: "center",
}

const NotFoundPage = () => {
  return (
    <Layout>
      <div style={mainContainer} className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1 style={headingStyles}>404: Page Not Found</h1>
            <div style={buttonContainer}>
              <Button type="jumboPurple" url="/" text="Return to home" newTab={false}/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <Seo title="Not found"/>
